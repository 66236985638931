<script setup lang="ts">
import {ref} from "vue";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'


const props = defineProps({
    metaItems: { type: Array, required: true },
    menuItems: { type: Array, required: true },
});
const menuItemsShort = props.menuItems.slice(0,6)
interface MenuItem {
    children: MenuItem[]
    hasActiveChild: boolean
    icon: string
    isActive: boolean
    key: string
    link: string
    properties: any
    title: string
    text: string
    ad: {
        link: string
        text: string
        imageSource: string
    }
    type: string
}
const isMenuOpen = ref(false)
const openedAccordion = 'item-1';
const getOnlyPageChildren = (children: MenuItem[], sliceEnd: number = 5) => {
    return children.filter((entry) => entry.type === 'page').slice(0, sliceEnd)
}
const hasMoreThanTwoLayers = (menuItem) => {
    return menuItem.children.some((child) => child.children.length > 0)
}
</script>

<template>

    <Popover :open="isMenuOpen" class="lg:hidden" >
                <div class="flex items-center justify-end ml-2 z-[70]">
                    <PopoverTrigger as-child>
                        <button v-if="!isMenuOpen" @click="isMenuOpen = true" type="button" class="-m-2.5 inline-flex transition-all ease-in-out duration-200 focus:bg-[#eb6042] items-center justify-center rounded-md p-1.5 text-white">
                            <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                        <button v-else type="button" @click="isMenuOpen = false" :class="['-m-2.5 rounded-md p-1.5 transition-all ease-in-out duration-200 focus:bg-[#eb6042] text-white', isMenuOpen && 'bg-[#eb6042]']">
                            <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </PopoverTrigger>
                    <div class="flex lg:hidden ml-auto">
                    </div>
                </div>
                 <PopoverContent class="h-screen flex lg:hidden justify-end w-screen pb-4 pt-8 border-t border-gray-100 rounded-0">
                     <div class="bg-stwh-rot-2024 sm:max-w-md w-full z-20 sm:px-6 px-4 relative overflow-y-scroll">
                         <div class="h-full relative mb-16 z-20">
                             <div class="space-y-2 pt-6 max-[375px]:pb-20 pb-16">
                                 <div class="flex flex-wrap justify-center space-x-3 ">
                                     <a v-for="item in metaItems.slice(0,3)" :key="item.key" :href="item.link" :class="['rounded-lg p-2 text-xs my-2 transition-all ease-in-out duration-150', item.isActive ? 'bg-white text-stwh-rot-2024' : 'text-white bg-stwh-rot-2024 border border-1']">{{item.title}}</a>
                                 </div>
                                 <Accordion as-child type="single" class="w-full" collapsible >
                                     <AccordionItem v-for="item in menuItems" :key="item.key" :value="item.key" >
                                         <AccordionTrigger class="text-base border-b border-white text-white font-semi-bold">{{ item.title }}</AccordionTrigger>
                                         <AccordionContent>
                                             <template v-if="item.children.length>0">
                                                 <Accordion v-if="hasMoreThanTwoLayers(item)" as-child type="single" class="w-full" collapsible >
                                                     <AccordionItem v-for="(child,index) in item.children" :key="child.key" :value="child.key" :class="[index === item.children.length - 1 ? 'border-none' : 'border-b']" >
                                                         <AccordionTrigger>
                                                             <a class="text-black font-semi-bold py-2 px-1 space-x-2 cursor-pointer" :href="child.link">
                                                                 <i v-if="child.icon"  :class="['text-base', child.icon]"></i>
                                                                 <span class="hover:bg-gray-100 transition-all duration-200 text-sm">
                                                        {{child.title}}
                                                    </span>
                                                             </a>
                                                         </AccordionTrigger>
                                                         <AccordionContent class="!py-0">
                                                             <ul v-if="child.children.length>0">
                                                                 <li v-for="(subchild, subIndex) in child.children">
                                                                     <a
                                                                         :href="subchild.link"
                                                                         :class="['select-none flex items-center space-x-2 p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-100 hover:text-white focus:bg-gray-100 focus:text-black', subIndex === child.children.length - 1 ? '' : 'border-b border-gray-200' ]"
                                                                     >
                                                                         <div v-if="subchild.icon">
                                                                             <i :class="['text-black text-base', subchild.icon]"></i>
                                                                         </div>
                                                                         <div class="flex flex-col space-y-1">
                                                                             <div :class="['text-sm text-black font-medium leading-none', !subchild.text && 'py-2']">{{subchild.title}}</div>
                                                                             <p v-if="subchild.text"  class="line-clamp-2 text-gray-800 text-xs leading-snug">
                                                                                 {{subchild.text}}
                                                                             </p>
                                                                         </div>
                                                                     </a>
                                                                 </li>

                                                             </ul>
                                                         </AccordionContent>
                                                     </AccordionItem>
                                                 </Accordion>
                                                 <ul v-else>
                                                     <li v-for="(child, index) in item.children">
                                                         <a
                                                             :href="child.link"
                                                             :class="['select-none flex items-center space-x-2 p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-100 hover:text-black focus:hover:bg-gray-100 focus:text-black', index === item.children.length - 1 ? '' : 'border-b border-gray-200']"
                                                         >
                                                             <div v-if="child.icon">
                                                                 <i :class="['text-black text-base', child.icon]"></i>
                                                             </div>
                                                             <div class="flex flex-col space-y-1">
                                                                 <div :class="['text-sm text-black font-medium leading-none', !child.text && 'py-2']">{{child.title}}</div>
                                                                 <p v-if="child.text"  class="line-clamp-2 text-gray-800 text-xs leading-snug">
                                                                     {{child.text}}
                                                                 </p>
                                                             </div>
                                                         </a>
                                                     </li>

                                                 </ul>
                                                 <div v-if="item.ad">
                                                     <a :href="item.ad.link" class="py-2 relative text-center">
                                                         <div v-if="item.ad.text" class="absolute top-8 z-10 z w-full text-white font-bold text-center" v-html="item.ad.text"></div>
                                                         <div class="bg-black opacity-25 z-[5] w-full h-full absolute rounded-md"></div>
                                                         <img alt="ads" class="hover:shadow-lg hover:bg-black/[.02] rounded-md w-full max-h-[150px] object-center z-[1]  object-cover mx-auto transition-all ease-in-out duration-200" :src="item.ad.imageSource" />
                                                     </a>
                                                 </div>
                                             </template>
                                             <a v-else
                                                :href="item.link"
                                                class=" select-none rounded-md flex space-x-2 p-3 leading-none no-underline outline-none transition-colors hover:bg-[#eb6042] hover:text-white focus:bg-[#eb6042] focus:text-white"
                                             >
                                                 <div v-if="item.icon">
                                                     <i :class="['text-white text-base', item.icon]"></i>
                                                 </div>
                                                 <div class="flex flex-col space-y-1">
                                                     <div :class="['text-sm text-white font-medium leading-none', !item.text && 'py-2']">{{item.title}}</div>
                                                     <p v-if="item.text"  class="line-clamp-2 text-gray-100 text-xs leading-snug">
                                                         {{item.text}}
                                                     </p>
                                                 </div>
                                             </a>

                                         </AccordionContent>
                                     </AccordionItem>
                                 </Accordion>
                             </div>
                         </div>
                         <div class=" flex left-0 sm:right-0 sm:left-auto fixed sm:max-w-md z-40 bottom-0 w-screen">
                             <div class="bg-stwh-rot-2024 border-t border-white flex w-full py-4 px-6">
                                 <a class="h-full flex justify-center items-center space-x-1 p-4 text-white" href="https://service.stadtwerke-herne.de/online/welcome">
                                     <i class="icon-stwh_Icons_-07 text-base"></i>
                                     <div class="text-sm mt-1 uppercase"> Kundenportal</div>
                                 </a>
                             </div>
                         </div>
                     </div>
                     <div class="fixed bg-black opacity-25 z-[2] w-full h-full"></div>

                 </PopoverContent>
    </Popover>

</template>

<style>
</style>
